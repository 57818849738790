<template>
  <div class="wrapper">
    <div class="sidebar">
        <div class="sidebar-item" :class="{ active: index === currentIndex }"
            @click="clickMenuItem(index)"
          v-for="(item, index) in getGoodList" :key="index">
          <img v-show="item.iconUrl" :src="item.iconUrl" alt="">
          <span>{{ item.title }}</span>
        </div>
    </div>
    <!--  -->
    <div class="foods-wrapper">
        <ul ref="foodsUl">
          <li
            class="food-list-hook"
            v-for="(good, index) in getGoodList"
            :key="index"
          >
            <h1 class="title">{{ good.title }}</h1>
            <ul>
              <li
                class="food-item"
                v-for="(food, index) in good.foods"
                :key="index"
              >
                <div class="icon">
                  <img width="57" height="57" :src="food.foodUrl" />
                </div>
                <div class="content">
                  <h2 class="name">{{ food.title }}</h2>
                  <!-- <p class="desc">{{ food.description }}</p> -->
                  <!-- <div class="extra">
                    <span class="count">月售{{ food.sellCount }}份</span>
                    <span>好评率{{ food.rating }}%</span>
                  </div> -->
                  <div class="price">
                    <span class="now"><span>¥</span><span>{{ food.price }}</span></span>
                    <!-- <span class="old" v-if="food.oldPrice"
                      >￥{{ food.oldPrice }}</span> -->
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
export default {
  name: '',
  components: {},
  data() {
    return {
      scrollY: 0, // 右侧滑动的Y轴坐标 (滑动过程时实时变化)
      tops: [], // 所有右侧分类li的top组成的数组  (列表第一次显示后就不再变化)
      menuList: [  // 左侧菜单
        { id: 1, title: '特色推荐', iconUrl: require('./assets/img/hot.png'), key: 'hot' },
        { id: 2, title: '家常小炒', iconUrl: '', key: 'beauty' },
        { id: 3, title: '秘制火锅', iconUrl: '', key: 'spa' },
        { id: 4, title: '酒水饮料', iconUrl: '', key: 'clear' },
      ],
      getGoodList:[
        {
            iconUrl: require('./assets/img/hot.png'),
            title: "特色推荐",
            key: 'hot',
            foods: [
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX商品名称",
                    price: '30.59',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX商品名称1",
                    // description: "双马尾",
                    // sellCount: 1,
                    // rating: 100,
                    price: '30.59',
                    // oldPrice: "6"
                }
            ]
        },
        {
            iconUrl: '',
            title: "家常小炒",
            key: '',
            foods: [
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX家常小炒",
                    price: '30.59',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX家常小炒1",
                    price: '30.59',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX家常小炒1",
                    price: '30.59',
                }
            ]
        },
        {
            iconUrl: '',
            title: "秘制火锅",
            key: '',
            foods: [
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX秘制火锅",
                    price: '123.56',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX秘制火锅1",
                    price: '30.59',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX秘制火锅1",
                    price: '30.59',
                }
            ]
        },
        {
            iconUrl: '',
            title: "酒水饮料",
            key: '',
            foods: [
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX酒水饮料",
                    price: '88.66',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX酒水饮料1",
                    price: '33.55',
                },
                {
                    foodUrl: require('./assets/img/dishes.png'),
                    title: "XXXX酒水饮料1",
                    price: '30.59',
                }
            ]
        },
      ]
     
    };
  },
  computed: {
    // 计算得到当前分类的下标
    currentIndex() {
      // 初始化和相关数据发生了变化时执行
      // 得到条件数据
      const { scrollY, tops } = this;
      // 根据条件计算产生一个结果
      const index = tops.findIndex((top, index) => {
        // scrollY>=当前top && scrollY<下一个top
        return scrollY >= top && scrollY < tops[index + 1];
      });
      // 返回结果
      return index;
    }
  },
  methods: {
    initView(){
        // 列表显示之后创建
      new BScroll(".sidebar", {
        click: true
      });
      this.foodsScroll = new BScroll(".foods-wrapper", {
        probeType: 2, // 因为惯性滑动不会触发
        click: true
      });

      // 给右侧列表绑定scroll监听
      this.foodsScroll.on("scroll", ({ x, y }) => {
        console.log("scroll", x, y);
        this.scrollY = Math.abs(y);
      });
      // 给右侧列表绑定scroll结束的监听
      this.foodsScroll.on("scrollEnd", ({ x, y }) => {
        console.log("scrollEnd", x, y);
        this.scrollY = Math.abs(y);
      });
    },
    // 初始化tops
    _initTops() {
      // 1. 初始化tops
      const tops = [];
      let top = 0;
      tops.push(top);
      // 2. 收集
      // 找到所有分类的li
      const lis = this.$refs.foodsUl.getElementsByClassName("food-list-hook");
      Array.prototype.slice.call(lis).forEach(li => {
        top += li.clientHeight;
        tops.push(top);
      });

      // 3. 更新数据
      this.tops = tops;
      console.log(tops);
    },

    clickMenuItem(index) {
      console.log(index);
      // 使用右侧列表滑动到对应的位置

      // 得到目标位置的scrollY
      const scrollY = this.tops[index];
      // 立即更新scrollY(让点击的分类项成为当前分类)
      this.scrollY = scrollY;
      // 平滑滑动右侧列表
      this.foodsScroll.scrollTo(0, -scrollY, 300);
    },

  },
  mounted(){
    this.$nextTick(() => {
        this.initView()
        this._initTops();
    });
    
  }
};
</script>
<style scoped>
.wrapper{
    display: flex;
    height: 100%;
    overflow: auto;
}
.sidebar {
  width: 8.85rem;
  background-color: #ededed;
}

.sidebar-item {
  padding: 1.46rem 1.46rem 1.46rem 0;
  /* padding-right: 1.46rem; */
  font-size: 1rem;
  text-align: right;
  background-color: #ededed;
}

.sidebar-item.active {
  background-color: #fff;
}

.sidebar-item img {
  width: 1.06rem;
  height: 1.23rem;
  margin-right: 3px;
}
.menuName{
  color: #333;
  font-size: 1.06rem;
  height: 1.06rem;
  margin: 0;
}
.foods-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 1.33rem;
}
.foods-wrapper .title {
    height: 1.06rem;
    line-height: 1.06rem;
    font-size: 1.06rem;
    color: #333;
    text-align: left;
    margin-top: 1.06rem
}
.food-item {
    display: flex;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
}
.icon {
    flex: 0 0 57px;
    margin-right: 10px;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}
.content .name {
    margin: 2px 0 8px 0;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    color: #333;
}

.desc, .extra {
    line-height: 10px;
    font-size: 10px;
    color: rgb(147, 153, 159);
}

.desc {
    line-height: 12px;
    margin-bottom: 8px;
}

.extra .count {
    margin-right: 12px;
}

.price {
    font-weight: 700;
    line-height: 24px;
}
.now {
    margin-right: 8px;
    font-size: 14px;
    color: #d81e06;
}
.now span:nth-child(1){
    font-size: 0.8rem;
}
.now span:nth-child(1){
    font-size: 1.06rem;
}
.old {
    text-decoration: line-through;
    font-size: 10px;
    color: rgb(147, 153, 159);
}


</style>
